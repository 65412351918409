<template>
  <div id="appContainer" :class="[infoopen?'infoOpen':'']">
    <navbar v-model="infoopen" :tags="getTagsInLang" />
    <router-view :articles="getArticlesInLang" :contactinfo="getInfoInLang" :tags="getTagsInLang" />
    <overlay :contactinfo="getInfoInLang" :infoopen="infoopen" />
  </div>
</template>
<script>
import { getUserLocales } from "get-user-locale";
import navbar from "@/components/Navigation.vue";

import overlay from "@/components/Info_Overlay.vue";
var d = JSON.parse(
  document.querySelector("script[type='application/json']").innerHTML
).data;
console.log(d);
d.tags_en.list.unshift({ title: "All", id: 0 });
d.tags_de.list.unshift({ title: "All", id: 0 });

var data = {
  ...d,
  infoopen: false,
};

export default {
  data() {
    return data;
  },
  components: {
    navbar,
    overlay,
  },
  mounted() {
    var langs = getUserLocales();
    var found = false;
    langs.forEach(
      function (l) {
        if (!found) {
          if (l == "de-DE" || l == "de") {
            this.$root.lang = "de";
            found = true;
          }
          if (l == "en-US" || l == "en-UK" || l == "en") {
            this.$root.lang = "en";
            found = true;
          }
        }
      }.bind(this)
    );
  },
  watch: {
    
  },
  computed: {
    getArticlesInLang() {
      // var currTag = this.$root.currTag;
      // var articles = this["article_" + this.$root.lang].list;
      // if (currTag == 0) {
      //   return articles;
      // } else {
      //   return articles.filter(function (article) {
      //     var isIn = false;
      //     article.tags.list.forEach(function (tag) {
      //       if (tag.id == currTag) {
      //         isIn = true;
      //       }
      //     });
      //     return isIn;
      //   });
      // }
      var articles = this["article_" + this.$root.lang].list;
      return articles;
    },
    getInfoInLang() {
      return this["contact_" + this.$root.lang].first;
    },
    getTagsInLang() {
      return this["tags_" + this.$root.lang].list;
    },
  },
};
</script>
<style lang="scss">
#appContainer {
  font-family: "Publico Text Mono", "Times New Roman", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $highlightColor2;
  padding: 10px;
  box-sizing: border-box;
  // overflow-y: auto;
  height: 100%;
}

#appContainer.infoOpen {
  overflow: hidden;
}

.colorContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50vw;
  overflow: hidden;
}
#purplePositioner,
#blackPositioner {
  width: 100vw;
  box-sizing: border-box;
  padding: 20px;
}
#blackSide {
  left: 50vw;
  #blackPositioner {
    transform: translateX(-50vw);
  }
}
#purpleSide {
  color: $highlightColor;
}

html,
body {
  padding: 0px;
  margin: 0px;
  // overflow: hidden;
  width: 100%;
  height: 100%;
}
body {
  // border: 1px solid red;
  box-sizing: border-box;
}
p {
  margin: 1em 0px;
  padding: 0px;
}
p:first-of-type {
  margin-top: 0px;
}
p:last-of-type {
  margin-bottom: 0px;
}
</style>
