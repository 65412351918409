<template>
  <div class="home">
    <drawingboard :firstPage="firstPage" />
    <div id="introOverlay" :class="!firstPage ? 'hidden' : ''" @click="triggerPageHide" @wheel.once="triggerPageHide = false">
      <div class="one">Art <div class="irrit">irritates</div><br />Law</div>
      <div class="two">Law <div class="irrit">irritates</div><br />Art</div>
      <div class="bottom">
        <div class="purple">
          <div class="bottomText" v-html="contactinfo.footer_text"></div>
        </div>
      </div>
    </div>
    <div class="bodyContainer">
      <articlelist :articles="articles" :tags="tags" />
      <footerpart :text="contactinfo.footer_text" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import articlelist from "@/components/ArticleList.vue";
import drawingboard from "@/components/DrawingBoard.vue";
import footerpart from "@/components/Footer.vue";
export default {
    data() {
        return {
            firstPage: !this.$root.articlePage,
        };
    },
    name: "Home",
    props: ["articles", "tags", "contactinfo"],
    components: {
        articlelist,
        drawingboard,
        footerpart,
    },
    methods: {
        triggerPageHide() {
            if (this.firstPage) {
                this.firstPage = false;
            }
        },
    },
    computed: {},
    watch: {
        firstPage: {
            immediate: true,
            handler: function (newValue, oldValue) {
                console.log(this);
                document.body.style.overflowY = newValue ? "hidden" : "auto";
                document.body.style.position = newValue ? "fixed" : "relative";
                document.body.style.height = "100%";
                document.body.addEventListener("wheel", this.triggerPageHide);
            },
        },
    },
};
</script>
<style lang="scss">
div.logoCont {
    width: 2em;
    position: relative;
    display: inline-block;
    padding: 3px;
    img.uniLogo {
        height: 2em;
    }
}
@media screen and (max-width: $width1) {
    img.uniLogo {
        height: 2em;
    }
}
</style>
<style lang="scss" scoped>
.home {
    height: 100%;
}

#introOverlay {
    background-color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 200;
    opacity: 1;
    transition: opacity 500ms;
    cursor: pointer;
}
#introOverlay.hidden {
    opacity: 0;
    pointer-events: none;
}
.one,
.two {
    font-family: "Publico Text Mono", "Times New Roman", serif;
    font-size: 40px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
}

.one {
    left: 25%;
    color: $highlightColor;
}
.two {
    left: 75%;
}

.bottom {
    width: 500px;
    max-width: calc(100% - 10px);
    position: absolute;
    text-align: center;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Publico Text", "Times New Roman", serif;
}

.purple {
    position: absolute;
    bottom: 0px;
    .bottomText {
        width: 100%;
    }
}

.purple {
    color: $highlightColor;
}
.black {
    left: 50%;
    .bottomText {
        transform: translateX(-50%);
    }
}

.irrit {
    display: inline-block;
}
.bodyContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media screen and (max-width: $width1) {
    .one,
    .two {
        left: 50%;
        width: 100%;
    }
    .one {
        top: 25%;
    }
    .two {
        top: 75%;
        // color: $highlightColo2r;
    }
    .bottom {
        bottom: 10px;
        font-size: $f1mobile;
    }
}
</style>

