var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{staticClass:"lazyload",attrs:{"data-srcset":_vm.generateSizes(_vm.image.variations),"width":_vm.image.width,"height":_vm.image.height,"src":'data:image/svg+xml;utf8,<svg width="' +
    _vm.image.width +
    '" height="' +
    _vm.image.height +
    '" viewBox="0 0 ' +
    _vm.image.width +
    ' ' +
    _vm.image.height +
    '" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg>',"sizes":_vm.sizes}})}
var staticRenderFns = []

export { render, staticRenderFns }