<template>
  <div id="overLayContainer" :class="[
      infoopen ? 'visible' : '',
      rightSideActive ? 'rightSideActive' : '',
    ]">
    <div class="leftSide" @click="rightSideActive = false" @mouseover="rightSideActive = false">
      <div class="leftSideContainer">
        <div class="info_text" v-html="contactinfo.info_text" />
        <div class="contacts_container">
          <div v-for="contact in contactinfo.contact_repeater.list" :key="contact.contact_name" class="contact">
            <div v-html="contact.contact_name" />
            <div v-html="contact.contact_title" />
            <a :href="'mailto:'+contact.contact_email" class="emailLink">
              <span class="link">
                <div class="left" />
                <div class="at">@</div>
                <div class="right" />
              </span>
              {{contact.contact_email}}
            </a>
          </div>
        </div>
        <div class="uniContactInfo">
          <div class="text" v-html="contactinfo.contact_university" />
          <a :href="'tel:'+contactinfo.contact_uni_telephone" class="emailLink">
            <span class="link">
              <div class="left" />
              <div class="at">T</div>
              <div class="right" />
            </span>
            {{contactinfo.contact_uni_telephone}}
          </a>
          <div v-html="logo" />
        </div>
      </div>

    </div>
    <div class="rightSide" @click="rightSideActive = true" @mouseover="rightSideActive = true">
      <div class="art_text">
        <pintro text="ART" color="1" :width="$root.isMobile ? 60 : 120" />
        <div v-html="contactinfo.art_text"></div>
      </div>
      <div class="law_text">
        <pintro text="LAW" color="1" :width="$root.isMobile ? 60 : 120" />

        <div v-html="contactinfo.law_text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import pintro from "@/components/Paragraph_Intro.vue";
export default {
    data() {
        return {
            rightSideActive: false,
        };
    },

    components: { pintro },
    props: ["contactinfo", "infoopen"],
};
</script>
<style scoped lang="scss">
#overLayContainer {
    position: fixed;
    z-index: 50;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    padding: 50px 10px 10px 10px;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms, transform 500ms;
}
#overLayContainer.visible {
    display: flex;
    pointer-events: all;
    opacity: 1;
}
.leftSide,
.rightSide {
    height: 100%;
    overflow-y: auto;
}
.leftSide {
    flex-basis: 50%;
    width: 50%;
    margin-right: 10px;
    color: $highlightColor;
    opacity: 1;
    transition: opacity 400ms;
}
.leftSideContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.rightSide {
    flex-basis: 50%;
    width: 50%;
    opacity: 0.2;
    transition: opacity 400ms;
}
#overLayContainer.rightSideActive {
    .rightSide {
        opacity: 1;
    }
    .leftSide {
        opacity: 0.2;
    }
}
// .leftSide:hover,
// .rightSide:hover {
//   opacity: 1;
// }
.art_text,
.law_text {
    position: relative;
    text-indent: 120px;
    font-size: $f3desktop;
}
.art_text {
    margin-bottom: 2em;
}

.leftSide {
    font-size: $f3desktop;
    font-family: "Publico Text Mono", "Times New Roman", serif;
}
.info_text {
    margin-bottom: 4em;
}

.emailLink {
    text-decoration: none;
}
.contact {
    margin-bottom: 1em;
}
.contact:last-of-type {
  margin-bottom: 4em;
}
.uniContactInfo {
  margin-top: auto;
}
.link {
    display: inline-block;
    width: 3em;
    height: 1em;
    position: relative;
    margin-right: 0.5em;
    pointer-events: none;
    .left,
    .right {
        width: 10%;
        height: 100%;
        border: 1px solid $highlightColor;
        position: absolute;
        top: 0px;
    }
    .left {
        left: 0px;
        border-right: 0px;
    }
    .right {
        right: 0px;
        border-left: 0px;
    }
    .at {
        font-size: 1em;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: $width1) {
    .art_text,
    .law_text {
        text-indent: 60px;
    }
    .art_text,
    .law_text,
    .info_text,
    .leftSide {
        font-size: $f1mobile;
        line-height: 16px;
    }
    #overLayContainer {
        width: 170vw;
        background-color: white;
        transform: translateX(0);
        padding: 0px 0px;
        .leftSide {
            opacity: 1;
            padding-right: 5px;
        }
        .rightSide {
            padding-left: 5px;
        }
    }
    #overLayContainer.rightSideActive {
        transform: translateX(-40%);
        .leftSide {
            opacity: 0.2;
        }
        .rightSide {
            opacity: 1;
        }
    }
    .leftSide,
    .rightSide {
        // opacity: 1;
        flex-basis: 50%;
        width: 50%;
        margin: unset;
        padding: 50px 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
}
</style>