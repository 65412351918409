<template>
  <div class="titlePositioner">
    <div class="titleCont">
      <span :class="['articleTitle', article.title.length > 60?'smaller':'']">

        <span v-html="part(0, article.title.replace(/-/g, '—'))" />
        <imagehelper v-if="article.art_image.length" :image="article.art_image[0]" />
        <span v-html="part(1, article.title.replace(/-/g, '—'))" />
      </span>
    </div>
    <div class="bottomlineCont">
      <span class="authors">
        <span class="artAuthor" v-html="keepTogether(article.art_author)" /><span class="separator"> — </span><span class="lawAuthor" v-html="keepTogether(article.law_author)" />
      </span>

      <span class="copyright" v-html="'&#169; ' + article.copyright" />
      <span :class="['tags', $root.currTag?'isTag':'']">
        <span :class="['tag', $root.currTag==tag.id?'selected':'']" v-for="tag in article.tags.list" :key="translate(tag.id).title" v-html="'&nbsp;#'+translate(tag.id).title" />
      </span>
    </div>

  </div>
</template>

<script>
import imagehelper from "@/components/Image.vue";
export default {
  props: ["article", "tags"],
  components: { imagehelper },
  methods: {
    part(n, text) {
      var ts = text.split(" ");
      var h = Math.floor(ts.length / 2);
      if (!n) {
        ts.splice(Math.max(h, 1));
      } else {
        ts.splice(0, h);
      }
      return ts.join(" ");
    },
    translate(tagID) {
      return this.tags.find(element => element.id == tagID);
    },
    keepTogether(text) {
      var parts = text.split(",");
      var newT = "";
      parts.forEach((part, i) => {
        newT += "<span style='display:inline-block'>" + part
        if (i != parts.length - 1) newT += ",&nbsp;"
        newT += "</span>";
      });
      return newT
    }
  },
};
</script>

<style lang="scss" scoped>
.titlePositioner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
}
.titleCont {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  user-select: none;
}
.copyright {
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  bottom: 7px;
  font-size: $f1desktop;
  line-height: 1em;
  font-family: "Publico Text Mono";
  color: $highlightColor;
}
.bottomlineCont {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.articleTitle {
  display: block;
  cursor: pointer;
  color: $highlightColor;
  position: relative;
  z-index: 2;
  padding: 0px 50px;
  img {
    display: inline-block;
    height: $f2desktop * 52/75;
    width: auto;
    margin: 0px 0.3em;
    transform-origin: center center;
    transform: scale(1, 1);
    transition: transform 300ms;
  }
  &.smaller {
    font-size: $f2small;

    line-height: 1em;
    img {
      height: $f2small * 52/75;
    }
  }
}
.authors,
.tags {
  color: $highlightColor;
  font-size: $f1desktop;
  line-height: 1em;
  font-family: "Publico Text Mono";
}
.authors {
  left: 10px;
}
.tags {
  right: 10px;
}
.tags.isTag {
  .tag {
    opacity: 0.5;
    transition: 500ms;
  }
  .tag.selected {
    opacity: 1;
  }
}

@media (hover: hover) and (pointer: fine) {
  .titlePositioner:hover {
    img {
      transform: scale(2, 2);
    }
  }
}
@media screen and (max-width: $width2) {
  .articleTitle {
    // transform: translateY(-25px);
    img {
      height: $f2desktop * 52/75;
    }
  }
}
@media screen and (max-width: $width1) {
  .copyright,
  .tags {
    display: none;
  }
  .titleBlack,
  .titlePurple {
    padding-top: 40px;
    color: $highlightColor;
  }
  .articleTitle {
    line-height: 1em;
    padding: 0px 0px;
    img {
      height: $f2mobile * 52/75;
    }
    &.smaller {
      font-size: $f3mobile;
      img {
        height: $f3mobile * 52/75;
      }
    }
  }

  .titleBlack {
    .articleTitle {
    }
  }
  .authors {
    font-size: $f1mobile;
    line-height: 1em;
    width: 100%;
    font-family: "Publico Text Mono";
    text-transform: uppercase;

    color: $highlightColor;
  }
}
</style>