var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['articleList', _vm.transitioning?'transitioning':'']},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"button","value":"hidden","id":"closeAndScroll"},on:{"click":_vm.closeAndScroll}}),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"button","value":"hidden","id":"startTrans"},on:{"click":_vm.startTrans}}),_vm._l((_vm.articles),function(article,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasTag(article)),expression:"hasTag(article)"}],key:article.id+article.title,class:{
      articleContainer: true,
      open: _vm.articlesMutable[i].open,
      articleVisible: _vm.hasTag(article)

    }},[(!_vm.$root.isMobile)?_c('div',{staticClass:"centerImageContainer"},[_c('div',{staticClass:"leftPusher"}),_c('div',{staticClass:"centerImage"},[_c('div',{staticClass:"centerImageFiller",style:({top : (_vm.articlesMutable[i].titleHeight+10)+'px'})},[_c('imagehelper',{attrs:{"image":article.art_image[0]}})],1),(_vm.$root.isMobile)?_c('span',{staticClass:"copyright",domProps:{"innerHTML":_vm._s('&#169; ' + article.copyright)}}):_vm._e()]),_c('div',{staticClass:"rightPusher"})]):_vm._e(),_c('router-link',{attrs:{"to":_vm.generateLink(article, i)}},[_c('div',{staticClass:"articleTitleContainer",style:({
        top: _vm.articlesMutable[i].titleHeight - _vm.titleHeight + 'px',
      })},[_c('articletitle',{attrs:{"article":article,"tags":_vm.tags}})],1)]),_c('div',{staticClass:"articleContentClipper",style:({ height: _vm.articlesMutable[i].height + 'px', 'will-change' : _vm.articlesMutable[i].transitioning?'height':'auto'  }),attrs:{"data-src":_vm.articlesMutable[i].height}},[_c('articlebody',{attrs:{"article":article}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }