<template>

  <div class="filterList">
    <div v-for="(tag, i) in tags" :key="tag.title+i" class="radio">
      <label>
        <span class="radio__input">
          <input type="radio" name="radio" :value="tag.id" :id="tag.id" :checked="i==0" @click="changeFilter(tag.id)" />
          <span class="radio__control">
            <div class="left" />
            <div class="bullet">•</div>
            <div class="right" />
          </span>
        </span>
        <span class="radio__label" v-html="tag.title" />
      </label>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtersOpen: false,
    };
  },
  props: ["tags", "open"],
  methods: {
    openMenu() {
      this.filtersOpen = !this.filtersOpen;
    },
    changeFilter(id) {
      this.$emit("clickedfilter", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.filterList {
  position: absolute;
  top: 35px;
  color: $highlightColor;
  pointer-events: all;
  line-height: 15px;
}

.radio {
  display: inline-block;
  margin-right: 10px;
  label {
    display: flex;
    cursor: pointer;
  }
  .radio__control .bullet {
    display: none;
    font-size: 25px;
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translate(-50%, -50%);
  }
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .radio__control .bullet {
      display: block;
    }
  }
  .radio__control {
    display: block;
    width: 1.5em;
    height: 1em;
    position: relative;
    margin-right: 0.5em;
    pointer-events: none;
    // border-radius: 50%;
    .left,
    .right {
      width: 10%;
      height: 100%;
      border: 1px solid $highlightColor;
      position: absolute;
      top: 0px;
    }
    .left {
      left: 0px;
      border-right: 0px;
    }
    .right {
      right: 0px;
      border-left: 0px;
    }
  }
}
@media screen and (max-width: $width1) {
  .filterList {
    top: 25px;
    text-align: left;
    line-height: 19px;
    padding-bottom: 4px;
  }
}
</style>