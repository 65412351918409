<template>
  <div class="articleContent" :id="'content' + article.id">

    <div class="inlineImage" v-if="$root.isMobile">
      <imagehelper v-if="article.art_image.length" :image="article.art_image[0]" />
      <span class="copyright" v-if="$root.isMobile" v-html="'&#169; ' + article.copyright" />
    </div>
    <div class="articlePart">
      <div class="left">
        <pintro text="ART" width="60" color="0" />
        <div class="article_text art_text" v-if="$root.isMobile">
          <div v-html="art_text" />
          <div class="article_quote inline" v-html="article.art_quote" />
          <div v-html="art_text2" />
        </div>
        <div class="article_text art_text" v-html="art_text" v-else />
      </div>
      <div class="center"></div>

      <div class="right">
        <div class="article_quote" v-html="article.art_quote" />
      </div>
    </div>

    <div class="articlePart">
      <div class="left">
        <div class="article_quote" v-html="article.law_quote" />
      </div>
      <div class="center" />

      <div class="right">
        <pintro text="LAW" width="60" color="1" />
        <div class="article_text law_text" v-if="$root.isMobile">
          <div v-html="law_text[0]" />
          <div class="article_quote inline" v-html="article.law_quote" />
          <div v-html="law_text[1]" />
        </div>
        <div class="article_text law_text" v-html="law_text[0]" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import imagehelper from "@/components/Image.vue";
import pintro from "@/components/Paragraph_Intro.vue";
export default {
  props: ["article"],
  components: { imagehelper, pintro },
  computed: {
    art_text() {
      if (!this.$root.isMobile) {
        return this.article.art_text;
      } else {
        var p = this.article.art_text.split("</p>");
        var newString = p[0] + "</p>";
        return newString;
      }
    },
    art_text2() {
      if (!this.$root.isMobile) {
        return "";
      } else {
        var p = this.article.art_text.split("</p>");
        p.splice(0, 1);
        var newString = p.join("</p>");
        return newString;
      }
    },
    law_text() {
      if (!this.$root.isMobile) {
        return [this.article.law_text];
      } else {
        var p = this.article.law_text.split("</p>");
        var first = p.splice(0, 1);
        var newString = p.join("</p>");
        return [first + "</p>", newString];
      }
    },
  },
};
</script>
<style lang="scss">
.articlePart {
  width: 100%;
  display: flex;
  font-size: $f3desktop;
  .left,
  .center,
  .right {
    position: relative;
  }
  .left,
  .right {
    max-width: 600px;
  }
  .left {
    flex-basis: 40%;
    color: #3c00ff;
  }
  .center {
    flex-basis: 20%;
    position: relative;
    padding: 20px;
    flex-grow: 2;
    box-sizing: border-box;
  }
  .right {
    flex-basis: 40%;
  }
  .article_text {
    @include paragraphDesktop;
    text-indent: 60px;
  }
  .article_quote {
    font-size: 200%;
    font-style: italic;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
  }
  .article_quote::before {
    border: 1px solid;
    border-right: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 100%;
    content: "";
    box-sizing: border-box;
  }
  .article_quote::after {
    border: 1px solid;
    border-left: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 100%;
    content: "";
    box-sizing: border-box;
  }
}

@media screen and (max-width: $width1) {
  .right,
  .copyright {
    color: $highlightColor;
  }

  .left {
    color: $highlightColor;
  }

  .right {
    color: $highlightColor2;
  }
  .articlePart {
    font-size: $f3mobile;
    flex-direction: column;
  }
  .article_quote {
    display: none;
  }
  .article_quote.inline {
    display: block;
    position: relative;
    transform: unset;
    width: 100%;
    left: 0px;
    box-sizing: border-box;
    font-size: $f2mobile;
    text-indent: 0px;
    text-align: center;
    margin: 10px 0px;
  }

  .articlePart .center {
    padding: 0px;
    transform: unset;
    .centerImage {
      transform: unset;
    }
  }

  .inlineImage {
    margin: 20px 0px;
    img {
      width: 100%;
      height: auto;
    }
    .copyright {
      font-size: 12px;
      display: block;
      text-align: center;
      font-family: "Publico Text Mono";
      text-transform: uppercase;
    }
  }
}
@media screen and (max-width: $width2) {
  .articlePart .article_text {
    @include paragraphMobile;
  }
}
</style>