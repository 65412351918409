<template>
  <div id="langSelector">
    <span @click="changeLang('de')" :class="{langChanger : true, active : currLang('de')}">DE</span>
    /
    <span @click="changeLang('en')" :class="{langChanger : true, active : currLang('en')}">EN</span>
  </div>
</template>
<script>
export default {
  computed: {
    
  },
  created() {
    console.log(this.$root.lang);
  },
  methods: {
    changeLang(l) {
      this.$root.lang = l;
      console.log(this.$root.lang);
    },
    currLang(l) {
      return this.$root.lang == l;
    }
  },
};
</script>

<style lang="scss">
#langSelector {
  
}
.langChanger {
  cursor: pointer;
  opacity: 0.5;
}
.langChanger.active, .langChanger:hover {
  opacity: 1;
}


@media screen and (max-width: $width1) {
    #langSelector {
        right: unset;
        left: 10px;
        color: $highlightColor;
    }
}
</style>