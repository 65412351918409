<template>
  <canvas id="c" :class="[!firstPage?'hidden':'', inactive?'no':'']" />
</template>
<script>
import simplex from "simplex-noise";
import SimplexNoise from "simplex-noise";
export default {
  props: ["firstPage"],
  data() {
    return {
      inactive: !this.firstPage,
      c: false,
      ctx: false,
      loop: false,
      mouse: {
        x: -10,
        y: -10,
      },
      mouseFollow: {
        x: -10,
        y: -10,
      },
      smoothRate: 0.1,
      mouseRecs: [[]],
      currMouseRec: false,
      started: false,
      touchdown: false,
      mousemove: false,
      touchmove: false,
      touchend: false,
      windowresize: false,
      lastAutoMove: 0,
      irritates: [],
      irrTimes: [0, 0],
      colors: ["#ff7f00", "#3c00ff"],
      startTime: new Date().getTime(),
      simplex: new SimplexNoise(),
      noiseOffset : Math.random()*100
    };
  },

  mounted() {
    if (this.firstPage) {
      this.c = document.getElementById("c");
      this.ctx = this.c.getContext("2d");

      this.loop = requestAnimationFrame(this.animate);
      this.handleCanvasResize();

      this.startTime = new Date().getTime();
      this.mousemove = document.body.addEventListener(
        "mousemove",
        this.updateMouse
      );
      this.windowresize = window.addEventListener(
        "resize",
        this.handleCanvasResize
      );
    }
  },
  watch: {
    firstPage: function (val) {
      setTimeout(
        function () {
          this.inactive = true;
          cancelAnimationFrame(this.loop);
          document.body.removeEventListener("mousemove", this.mousemove);
          window.removeEventListener("resize", this.windowresize);
        }.bind(this),
        1500
      );
    },
  },
  methods: {
    handleCanvasResize() {
      this.c.width = 2 * this.$root.w;
      this.c.height = 2 * this.$root.h;
      this.ctx.scale(2, 2);
      this.ctx.translate(this.$root.w / 2, this.$root.h / 2);

      if (this.$root.isMobile) {
        document.querySelectorAll(".irrit").forEach(
          function (irrit, i) {
            this.irritates[i] = irrit.getBoundingClientRect();
          }.bind(this)
        );
      }
    },
    lerp(val1, val2, t) {
      return (val2 - val1) * t + val1;
    },
    animate() {
      this.loop = requestAnimationFrame(this.animate);
      var startTime = 1000;
      if (this.irritates.length) {
        if (this.mouseRecs.length == 1) this.mouseRecs.push([]);
        this.irritates.forEach(
          function (irr, i) {
            var noise = {
              x:
                ((this.simplex.noise2D(
                  0 + 100 * i + this.noiseOffset,
                  (new Date().getTime() - this.startTime) / 2000
                ) /
                  2 +
                  0.5) *
                  this.c.width) /
                2,
              y:
                ((this.simplex.noise2D(
                  10 + 100 * i+ this.noiseOffset,
                  (new Date().getTime() - this.startTime) / 2000
                ) /
                  2 +
                  0.5) *
                  this.c.height) /
                2,
            };
            var underline = {
              x: this.lerp(
                irr.left,
                irr.left + irr.width,
                i == 0
                  ? (new Date().getTime() - this.startTime) / startTime
                  : 1 - (new Date().getTime() - this.startTime) / startTime
              ),
              y: irr.top + irr.height,
            };
            var t = Math.min(
              Math.max((new Date().getTime() - this.startTime - 2000) / 1000, 0),
              1
            );
            var target = {
              x: this.lerp(underline.x, noise.x, t),
              y: this.lerp(underline.y, noise.y, t),
            };
            if (this.mouseRecs[i].length) {
              var lastPoint = this.mouseRecs[i][this.mouseRecs[i].length - 1];
              this.mouseRecs[i].push({
                x: (target.x - lastPoint.x) / 100 + lastPoint.x,
                y: (target.y - lastPoint.y) / 100 + lastPoint.y,
              });
            }
            else {
              this.mouseRecs[i].push({
                x: target.x,
                y: target.y
              })
            }
          }.bind(this)
        );
        this.drawPathsMobile();
      } else {
        this.mouseFollow.x +=
          (this.mouse.x - this.mouseFollow.x) * this.smoothRate;
        this.mouseFollow.y +=
          (this.mouse.y - this.mouseFollow.y) * this.smoothRate;

        var pushPoint = true;
        this.currMouseRec = this.mouseRecs[this.mouseRecs.length - 1];
        if (this.started && this.touchDown) {
          if (this.currMouseRec.length) {
            if (
              this.distance(
                this.currMouseRec[this.currMouseRec.length - 1],
                this.mouseFollow
              ) < 10
            ) {
              pushPoint = false;
            }
          }
          if (pushPoint) {
            this.currMouseRec.push({
              x: this.mouseFollow.x,
              y: this.mouseFollow.y,
            });
          }
        }
        this.drawPaths();
      }
    },
    distance(v1, v2) {
      return Math.pow(v2.x - v1.x, 2) + Math.pow(v2.y - v1.y, 2);
    },
    drawPathsMobile() {
      this.ctx.clearRect(
        -this.c.width / 2,
        -this.c.height / 2,
        this.c.width,
        this.c.height
      );
      this.ctx.save();
      this.ctx.translate(-this.c.width / 4, -this.c.height / 4);
      this.mouseRecs.forEach(
        function (path, i) {
          if (path.length) {
            this.ctx.beginPath();
            this.ctx.moveTo(path[0].x, path[0].y);
            path.forEach(
              function (p) {
                this.ctx.lineTo(p.x, p.y);
              }.bind(this)
            );
            this.ctx.strokeStyle = this.colors[i];
            this.ctx.stroke();
          }
        }.bind(this)
      );
      this.ctx.restore();
    },
    drawPaths() {
      this.ctx.clearRect(
        -this.c.width / 2,
        -this.c.height / 2,
        this.c.width,
        this.c.height
      );
      this.mouseRecs.forEach(
        function (path) {
          if (path.length) {
            this.ctx.beginPath();
            this.ctx.moveTo(path[0].x, path[0].y);
            path.forEach(
              function (p) {
                this.ctx.lineTo(p.x, p.y);
              }.bind(this)
            );
            this.ctx.strokeStyle = "#ff7f00";
            this.ctx.stroke();

            this.ctx.beginPath();
            this.ctx.moveTo(-path[0].x, -path[0].y);
            path.forEach(
              function (p) {
                this.ctx.lineTo(-p.x, -p.y);
              }.bind(this)
            );
            this.ctx.strokeStyle = "#3c00ff";
            this.ctx.stroke();
          }
        }.bind(this)
      );
    },
    updateMouse(e) {
      this.touchDown = true;
      this.updateDraw({ x: e.clientX, y: e.clientY });
    },
    touchStart(e) {
      this.touchDown = true;
      this.mouseFollow.x = -10;
      this.updateDraw({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    },
    updateTouch(e) {
      // this.updateDraw({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    },
    touchEnd() {
      this.started = false;
      this.touchDown = false;
      this.mouseRecs.push([]);
    },
    updateDraw(p) {
      if (!this.$root.isMobile) {
        this.mouse.x = p.x - this.$root.w / 2;
        this.mouse.y = p.y - this.$root.h / 2;
        if (!this.started) {
          this.started = true;
          this.mouseFollow.x = p.x - this.$root.w / 2;
          this.mouseFollow.y = p.y - this.$root.h / 2;
        }
      }
    },
  },
  props: ["firstPage"],
};
</script>
<style lang="scss" scoped>
canvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 1000;
  opacity: 1;
  transition: opacity 800ms 0ms;
}
canvas.hidden {
  opacity: 0;
}
canvas.no {
  display: none;
}
</style>