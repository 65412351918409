<template>
  <div class="footer">
    <div class="aligner">
      <div v-html="text" class="bottom">

      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ["text"],
    computed : {
    }
};
</script>
<style lang="scss" scoped>
.footer {
    font-family: "Publico Text", Georgia, "Times New Roman", Times, serif;
    text-align: center;
    color: $highlightColor;
    height: 200px;
    box-sizing: border-box;
    // padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 2;
    min-height: 200px;
    padding-bottom: 10px;
}
.aligner {
    text-align: center;
    .bottom {
        width: 500px;
        max-width: calc(100% - 10px);
        display: inline-block;
        position: relative;
    }
}
@media screen and (max-width: $width1) {
    .footer {
        height: 140px;
        font-size: $f1mobile;
        min-height: 140px;
    }
}
</style>