import Vue from 'vue'
import App from './App.vue'
import router from './router'

import lazyload from "lazyload";

console.log(lazyload)

Vue.config.productionTip = false
var data = {
  lang: "de",
  isMobile: window.innerWidth < 800,
  w: window.innerWidth,
  h: window.innerHeight,
  articlePage: false,
  currArticle: 0,
  currTag: 0,
  lazyload: new lazyload(),
  filtersOpen: false
};
var mixin = {
  computed: {
    logo() {
      return "<div class='logoCont'><img class='uniLogo' src='" + (process.env.NODE_ENV !== "production" ? "/artlaw" : "") + "/unilogo.svg' /></div>";
    },
  }
}

Vue.mixin(mixin)

new Vue({
  router,
  render: h => h(App),
  data() {
    if (this.$route.params.articleid) {
      data.articlePage = true;
    }
    return data
  }
}).$mount('#app')





window.addEventListener("resize", function () {
  data.isMobile = window.innerWidth < 800;
  data.w = window.innerWidth;
  data.h = window.innerHeight
})
