<template>
  <img
    :data-srcset="generateSizes(image.variations)"
    :width="image.width"
    :height="image.height"
    :src="
      'data:image/svg+xml;utf8,<svg width=&quot;' +
      image.width +
      '&quot; height=&quot;' +
      image.height +
      '&quot; viewBox=&quot;0 0 ' +
      image.width +
      ' ' +
      image.height +
      '&quot; version=&quot;1.1&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; xmlns:xlink=&quot;http://www.w3.org/1999/xlink&quot;></svg>'
    "
    :sizes="sizes"
    class="lazyload"
  />
</template>
<script>
export default {
  props: ["image", "ratio", "sizes"],
  methods: {
    generateSizes(sizes) {
      var s = "";
      sizes.forEach((element) => {
          // console.log(element)
        if (s != "") s += ", ";
        s +=
          "" +
          element.url +
          " " +
          Math.floor(element.width * (this.ratio ? this.ratio : 1)) +
          "w";
      });
      return s;
    },
  },
};
</script>