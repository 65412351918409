<template>
  <div id="navContainer" :style="getHeight">
    <div id="innerNav">
      <div class="filtersAndButton" :style="{
          opacity : this.infoopen?0:1
      }">
        <span class="openButton" @click="$root.filtersOpen = !$root.filtersOpen" v-html="filterText"></span>
        <filters :tags="tags" :open="$root.filtersOpen" @clickedfilter="clickedOnFilter" />
      </div>
      <div id="siteTitle" @click="closeAndScroll">
        <span class="col1">Art irritates Law, </span>
        <span class="col2">Law irritates Art</span>
      </div>
      <div id="infoAndLang">
        <div class="info" @click="handleClick">
          {{ infoopen ? "Close" : "Info" }}
        </div>
        <langselector />
      </div>
    </div>
    <!-- <div id="navBorder">

    </div> -->
  </div>
</template>
<script>
import langselector from "@/components/Lang.vue";
import filters from "@/components/Filters.vue";
export default {
    components: { langselector, filters },
    model: {
        prop: "infoopen",
        event: "click",
    },
    props: {
        infoopen: Boolean,
        tags: Array,
    },
    methods: {
        handleClick() {
            if (!this.infoopen) {
                this.$root.filtersOpen = false;
            }
            this.$emit("click", !this.infoopen);
        },
        closeAndScroll() {
            if (this.infoopen) {
                this.handleClick();
            } else {
                document.getElementById("closeAndScroll").click();
            }
        },
        clickedOnFilter(arg) {
            console.log(arg);
            if (this.$root.isMobile) {
                this.$root.filtersOpen = false;
            }
            this.closeAndScroll();
            document.getElementById("startTrans").click();
            setTimeout(
                function () {
                    this.$root.currTag = arg;
                }.bind(this),
                500
            );
        },
    },
    computed: {
        getHeight() {
            var h = 45;
            if (this.$root.filtersOpen) {
                h += document.querySelector(".filterList").getBoundingClientRect().height;
            }
            return {
                height: h + "px",
            };
        },
        filterText() {
            return this.$root.filtersOpen ? "Close Filters" : "Open Filters";
        },
    },
};
</script>

<style lang="scss">
#navContainer {
    position: fixed;
    top: 0px;
    width: 100%;
    padding: 0px 10px 10px 10px;
    box-sizing: border-box;
    left: 0px;
    font-size: $f1desktop;
    z-index: 100;
    // background-color: red;
    height: 45px;
    transition: height 300ms;
    pointer-events: none;
    #innerNav {
        width: 100%;
        background-color: #fff;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }
    #navBorder {
        position: relative;
        width: 100%;
        height: 20px;
        border: 1px solid $highlightColor;
        border-top: 0px;
        bottom: 20px;
        pointer-events: none;
    }
}
#siteTitle {
    color: $highlightColor;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    .col1 {
        color: $highlightColor;
    }
    .col2 {
        color: $highlightColor2;
    }
}
#infoAndLang {
    // position: absolute;
    // right: 0px;
    // top: 0px;
    display: flex;
    // padding: 10px;
    box-sizing: border-box;
    pointer-events: all;
}

.info {
    margin-right: 20px;
    cursor: pointer;
}
.filtersAndButton {
    color: $highlightColor;
    pointer-events: all;
    transition: opacity 500ms;
}
.openButton {
    cursor: pointer;
}

@media screen and (max-width: $width1) {
    #siteTitle {
        display: none;
    }
    #infoAndLang {
        // position: absolute;
        left: 0px;
        right: unset;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;

        font-size: $f1mobile;
    }
    .info {
        margin-right: 0px;
    }

    .filtersAndButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        font-size: $f1mobile;
        width: 100%;
        text-align: center;
        pointer-events: none;
        .openButton {
            pointer-events: all;
        }
    }
}
</style>