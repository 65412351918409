<template>
    <div :class="['pIntro', 'color'+color]" :style="{width : Math.floor(width-5)+'px'}">
        <div class="leftborder">&nbsp;</div>
        <div class="text">{{text}}</div>
        <div class="rightborder">&nbsp;</div>
    </div>
</template>

<script>

export default {
    props: ["text", "color", "width"]
}
</script>

<style lang="scss" scoped>
    .pIntro {
        text-indent: 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-family: "Publico Text Mono", "Times New Roman", serif;
        .text {
            text-align: center;
        }
        
    }
    .leftborder, .rightborder {
        position:absolute;
        top: 0px;
        width: 20%;
        border-width: 0.5px ;
        border-style: solid;
        border-color: inherit;
        height: 90%;
    }
    .leftborder {
        left: 0px;
        border-right-width: 0px;
    }
    .rightborder {
        right: 0px;
        border-left-width: 0px;
    }
    .color0 {
        border-color: $highlightColor;
    }
</style>