<template>
  <div :class="['articleList', transitioning?'transitioning':'']">
    <input type="button" value="hidden" @click="closeAndScroll" id="closeAndScroll" style="display: none" />
    <input type="button" value="hidden" @click="startTrans" id="startTrans" style="display: none" />
    <div v-for="(article, i) in articles" :key="article.id+article.title" :class="{
        articleContainer: true,
        open: articlesMutable[i].open,
        articleVisible: hasTag(article)

      }" v-show="hasTag(article)">
      <div class="centerImageContainer" v-if="!$root.isMobile">
        <div class="leftPusher"></div>
        <div class="centerImage">
          <div class="centerImageFiller" :style="{top : (articlesMutable[i].titleHeight+10)+'px'}">
            <imagehelper :image="article.art_image[0]" />
          </div>
          <span class="copyright" v-if="$root.isMobile" v-html="'&#169; ' + article.copyright" />
        </div>

        <div class="rightPusher"></div>
      </div>
      <router-link :to="generateLink(article, i)">
        <div class="articleTitleContainer" :style="{
          top: articlesMutable[i].titleHeight - titleHeight + 'px',
        }">
          <articletitle :article="article" :tags="tags" />
        </div>
      </router-link>

      <div class="articleContentClipper" :data-src="articlesMutable[i].height" v-bind:style="{ height: articlesMutable[i].height + 'px', 'will-change' : articlesMutable[i].transitioning?'height':'auto'  }">
        <articlebody :article="article" />
      </div>
    </div>
  </div>
</template>

<script>
import imagehelper from "@/components/Image.vue";
import articlebody from "@/components/ArticleBody.vue";
import articletitle from "@/components/ArticleTitle.vue";
import lazyload from "lazyload";
import bez from "bezier-easing";

export default {
    data() {
        var mut = [];
        this.articles.forEach(
            ((el, i) => {
                mut.push({});
                mut[i].height = 0;
                mut[i].maxHeight = this.$root.isMobile ? 120 : 220;
                mut[i].open = false;
                mut[i].left = Math.random() * 90;
                mut[i].titleHeight = 220;
                mut[i].changeTime = 0;
                mut[i].bounds;
                mut[i].article = el;
                mut[i].transitioning = false;
                mut[i].graph = { x: [], y: [] };
                if (this.$root.articlePage) {
                    if (this.getEnd(el.url) == this.$route.params.articleid) {
                        setTimeout(
                            function () {
                                this.openArticle(i);
                            }.bind(this),
                            500
                        );
                    }
                }
            }).bind(this)
        );

        return {
            articlesMutable: mut,
            targetTitle: 0,
            currOffset: 0,
            targetScroll: 0,
            actualTarget: 0,
            transitionStart: 0,
            shouldTransition: false,
            currArticle: false,
            scrollingToTop: false,
            transitioning: false,
            transitionTime: 700,
            graph: { x: [], y: [] },
        };
    },
    watch: {
        $route(to, from) {
            var path = "";
            if (to.name == "Home") {
                path = from.fullPath;
            } else {
                path = to.fullPath;
            }
            if (path.charAt(path.length - 1) != "/") path += "/";
            this.articles.forEach(
                ((el, i) => {
                    if (this.getEnd(el.url) == this.getEnd(path)) {
                        this.openArticle(i);
                    }
                }).bind(this)
            );
        },
    },
    props: ["articles", "tags"],
    components: { imagehelper, articlebody, articletitle },
    created() {},
    mounted() {
        var lazy = new lazyload();
        requestAnimationFrame(this.animatingContent);
    },
    updated() {
        var lazy = new lazyload();
    },
    methods: {
        generateLink(art, i) {
            if (this.articlesMutable[i].open) {
                return { name: "Home" };
            } else {
                return { name: "Article", params: { articleid: this.getEnd(art.url) } };
            }
        },
        getEnd(url) {
            var splitURL = url.split("/");
            return splitURL[splitURL.length - 2];
        },
        closeAndScroll() {
            this.shouldTransition = true;
            this.transitionStart = new Date().getTime();
            this.targetTitle = 0;
            this.currOffset = document.querySelectorAll(".articleContainer")[0].getBoundingClientRect().top;
            this.closeAllArticles();
        },
        closeAllArticles() {
            this.articlesMutable.forEach(
                function (article) {
                    if (article.open) {
                        article.open = false;
                        article.changeTime = new Date().getTime();
                        article.transitioning = true;
                    }
                }.bind(this)
            );
        },
        ease(n) {
            // return bez(0.25, 0.1, 0.25, 1.0)(n);
            return -(Math.cos(Math.PI * n) - 1) / 2;
        },
        lerp(b, e, t) {
            return (e - b) * t + b;
        },
        openArticle(n) {
            var article = this.articlesMutable[n];
            article.transitioning = true;
            var bottomPadding = this.$root.isMobile ? document.querySelectorAll(".titlePositioner .bottomlineCont")[n].getBoundingClientRect().height : this.paddingBottom;
            var innerTitleHeight = document.querySelectorAll(".titleCont .articleTitle")[n].getBoundingClientRect().height;
            article.titleHeight = innerTitleHeight + this.paddingTop + bottomPadding;
            this.$root.filtersOpen = false;
            this.actualTarget = n;
            if (!article.open) {
                this.closeAllArticles();
                article.maxHeight = document.querySelectorAll(".articleContent")[n].getBoundingClientRect().height;
                article.open = true;
                this.currOffset = document.querySelectorAll(".articleContainer")[n].getBoundingClientRect().top;
                this.targetScroll = 0;
                document.querySelectorAll(".articleVisible").forEach(
                    function (div, i) {
                        if (div == document.querySelectorAll(".articleContainer")[n]) {
                            n = i;
                        }
                    }.bind(this)
                );
            } else {
                article.open = false;
                this.currOffset = document.querySelectorAll(".articleContainer")[n].getBoundingClientRect().top;
                var pageHeight = Math.max(document.querySelectorAll(".articleVisible").length * this.titleHeight + 1 + this.sitePadding, window.innerHeight);
                //adjusting n
                document.querySelectorAll(".articleVisible").forEach(
                    function (div, i) {
                        if (div == document.querySelectorAll(".articleContainer")[n]) {
                            n = i;
                        }
                    }.bind(this)
                );
                if (n * this.titleHeight + window.innerHeight > pageHeight) {
                    this.targetScroll = n * this.titleHeight - (pageHeight - window.innerHeight);
                    if (pageHeight == window.innerHeight) {
                        this.targetScroll += this.topSitePadding + 10;
                        console.log("case");
                    }
                } else {
                    this.targetScroll = 0;
                }
            }

            this.targetTitle = n;
            article.changeTime = new Date().getTime();

            this.shouldTransition = true;
            this.transitionStart = new Date().getTime();
            this.graph.x = [];
            this.graph.y = [];
            document.body.style.height = "100000px";
        },
        animatingContent() {
            requestAnimationFrame(this.animatingContent);
            var targetY = 10 + this.topSitePadding;
            var n = 0;
            this.articlesMutable.forEach(
                function (article, i) {
                    var thisper = this.ease(Math.min(Math.max(0, (new Date().getTime() - article.changeTime) / (this.transitionTime / 1.2)), 1));
                    if (document.querySelectorAll(".articleContainer")[i].classList.contains("articleVisible")) {
                        if (article.transitioning) {
                            if (article.open) {
                                article.height = thisper * article.maxHeight;
                            } else {
                                article.height = (1 - thisper) * article.maxHeight;
                            }
                            if ((thisper) >= 1) {
                                article.transitioning = false;
                            }
                        } 

                        if (n < this.targetTitle && this.hasTag(article.article)) {
                            targetY += article.height + this.titleHeight;
                        }
                        n++;
                    }
                }.bind(this)
            );
            var per = 1;
            if (new Date().getTime() - this.transitionStart < this.transitionTime) {
                per = this.ease((new Date().getTime() - this.transitionStart) / this.transitionTime);
                var titleOffset = -(this.titleHeight - this.articlesMutable[this.actualTarget].titleHeight);
                var scrollY = targetY - this.lerp(this.currOffset, this.targetScroll ? this.targetScroll : titleOffset, per);
                // console.log(this.actualTarget);
                this.graph.x.push(new Date().getTime() - this.transitionStart);
                this.graph.y.push(scrollY);
                window.scroll(0, scrollY);
            } else if (this.shouldTransition) {
                var titleOffset = -(this.titleHeight - this.articlesMutable[this.actualTarget].titleHeight);
                var scrollY = targetY - (this.targetScroll ? this.targetScroll : titleOffset);
                window.scroll(0, scrollY);

                console.log(JSON.stringify(this.graph));
                this.shouldTransition = false;
                document.body.style.height = "100%";
            }
        },
        startTrans() {
            this.transitioning = true;
            setTimeout(
                function () {
                    window.scroll(0, 0);
                    this.transitioning = false;
                }.bind(this),
                500
            );
        },
        hasTag(article) {
            var currTag = this.$root.currTag;
            if (currTag == 0) {
                return true;
            } else {
                var isIn = false;
                article.tags.list.forEach(function (tag) {
                    if (tag.id == currTag) {
                        isIn = true;
                    }
                });
                return isIn;
            }
        },
    },
    computed: {
        titleHeight() {
            return this.$root.isMobile ? 120 : 220;
        },
        paddingBottom() {
            return this.$root.isMobile ? 25 : 20;
        },
        paddingTop() {
            return this.$root.isMobile ? 45 : 45;
        },
        pagePadding() {
            return this.$root.isMobile ? 10 : 10;
        },
        sitePadding() {
            return this.$root.isMobile ? 140 : 200;
        },
        topSitePadding() {
            return this.$root.isMobile ? 30 : 30;
        },
    },
};
</script>
<style lang="scss">
.articleContent {
    // margin-top: 210px;
    // position: absolute;
    // width: 100%;
    padding-top: 10px;
    font-family: "Publico Text";
    position: absolute;
    width: 100%;
}
@media screen and (max-width: $width1) {
    .articleContent {
        // margin-top: 160px;
    }
}

@media screen and (min-width: $width1) {
    .centerImageContainer {
        position: absolute;
        top: 0px;
        display: flex;
        height: 100%;
        width: 100%;
        .leftPusher,
        .rightPusher {
            max-width: 600px;
            flex-basis: 40%;
            flex-shrink: 0;
        }
        .centerImage {
            padding: 10px 20px 0px 20px;
            flex-grow: 2;
            position: relative;
            top: 220px;
            height: calc(100% - 220px);
            pointer-events: none;
            box-sizing: border-box;
            .centerImageFiller {
                position: sticky;
                width: 100%;
                padding-bottom: 0%;
                top: 220px;
                height: 0px;
                img {
                    object-fit: contain;
                    object-position: top center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }
        }
    }

    .open .centerImage .centerImageFiller {
        padding-bottom: 50vh;
    }
}
</style>
<style lang="scss" scoped>
a {
    text-decoration: none;
}
.articleList {
    opacity: 1;
    transition: opacity 300ms;
    padding-top: 30px; //valueMargin
}
.articleList.transitioning {
    opacity: 0;
}
.articleContainer {
    position: relative;
    // height: 220px;
    // opacity: 1;
}

.articleContainer:last-of-type {
    margin-bottom: 0px;
}
.overlayArticle > div {
    background-color: white;
}
.articleTitleContainer::after {
    border: 1px solid $highlightColor;
    border-top: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    content: "";
    box-sizing: border-box;
}

.articleTitleContainer {
    font-size: $f2desktop;
    line-height: 1.1em;
    font-family: "Publico Headline";
    height: 220px;
    display: block;
    text-align: center;
    top: 0px;
    width: 100%;

    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    z-index: 30;
}
.articleContainer.open .articleTitleContainer {
    // background-color: white;
}
.articleImages {
    position: absolute;
    // display: none;
    opacity: 0;
    transition: opacity 500ms;
    width: 300px;
    height: 100px;
    // top: 220px;
    z-index: -10;
    transform: translateY(150px);
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}
.articleContentClipper {
    height: 0px;
    overflow: hidden;
    position: relative;
    // transition: height 600ms;
}
.open .articleContentClipper {
    // overflow: visible;
}

@media screen and (max-width: $width2) {
    .artAuthor,
    .lawAuthor,
    .copyright {
        font-size: $f1small;
    }
}
@media screen and (max-width: 1300px) {
    .articleTitleContainer {
        font-size: $f2small;
        line-height: 1em;
    }
}

@media screen and (max-width: $width1) {
    .articleTitleContainer {
        height: 120px;
        font-size: $f2mobile;
    }
    .articleTitleContainer::after {
        border: 1px solid $highlightColor;
        border-top: 0px;
    }

    .artAuthor,
    .lawAuthor {
        font-size: $f1mobile;
        text-transform: uppercase;
    }
    .copyright {
        display: none;
    }
    .articleImages {
        // display: none;
        width: 75px;
    }
}
</style>
