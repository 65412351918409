import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

function beginning() {
  return (process.env.NODE_ENV !== 'production' ? "/artlaw" : "");
}

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: beginning() + "/",
      name: 'Home',
      component: Home,
      props: true
    },
    {
      path: beginning() + "/articles",
      name: 'Articles',
      component: Home,
      props: true
    },
    {
      path: beginning() + "/articles/:articleid/",
      name: 'Article',
      component: Home,
      props: true
    },
  ],
  scrollBehavior(to, from, savedPosition) {
        // console.log(to, from, savedPosition)
        return new Promise((resolve, reject) => {

          resolve({ left: 0, top: 0 })
        });
      }
  })

export default router

